/** @jsx jsx */
import { jsx } from 'theme-ui';

import { P } from '../components/Text';
import { Ul, Li } from '../components/List';
import { CodeOfConductLayout } from '../layouts/CodeOfConductLayout';

const CodeOfConductPage = () => {
  return (
    <CodeOfConductLayout>
      <P sx={{ fontSize: '24px' }}>
        The IxDA seeks to advance the discipline of interaction design by
        fostering a community of passionate individuals dedicated to moving our
        mission forward.
      </P>
      <P>
        Our mission is broad and inclusive, and our membership is passionate and
        diverse. While these attributes make the IxDA a strong and vibrant
        organization, they also bring the possibility of disagreement and
        cultural disconnect. As a volunteer-led organization we rely on the
        active participation of our community, which can only happen if all of
        our members feel safe and respected.
      </P>
      <P>
        To achieve this goal, the IxDA respectfully asks the following of our
        membership, both for online and in-person interactions under our brand:
      </P>
      <Ul>
        <Li>
          To be considerate and respectful of other cultures, backgrounds and
          perspectives
        </Li>
        <Li>
          To maintain a professional manner and refrain from harassment or
          exclusionary behavior toward other members
        </Li>
        <Li>
          To endeavor to resolve misunderstandings directly, through open
          dialogue and respectful feedback
        </Li>
        <Li>
          To notify the organizers or moderators if you feel you have
          experienced harassment or have witnessed the harassment of others.
        </Li>
      </Ul>
      <P>
        If a disagreement, harassment or uncomfortable situation persists, the
        IxDA is committed to providing support and resolution. In a physical
        setting such as a conference or local event, contact the organizers
        immediately. Participants asked to stop any harassing behavior are
        expected to comply immediately. Additionally, our online forums have
        moderators who can take action on members creating a hostile environment
        for others. Consequences can include removal from the forum and
        suspension of the member’s IxDA account.
      </P>
      <P>
        We welcome you to contact IxDA leadership at any time to report an issue
        or to give feedback on our efforts to create a welcoming and respectful
        environment for all.
      </P>
      <P>
        If you want to talk directly to one of our leadership team, here’s how
        to get in touch:
      </P>
      <Ul>
        <Li>
          Brenda Sanderson, Executive Director -{' '}
          <a
            href="mailto:bsanderson@ixda.org"
            sx={{ variant: 'variants.link', fontWeight: 'regular' }}
          >
            bsanderson@ixda.org
          </a>
          ,{' '}
          <a
            href="tel:+15142970406"
            sx={{ variant: 'variants.link', fontWeight: 'regular' }}
          >
            +1 514 297 0406
          </a>
        </Li>
        <Li>
          Kevin Budelmann, President -{' '}
          <a
            href="mailto:president@ixda.org"
            sx={{ variant: 'variants.link', fontWeight: 'regular' }}
          >
            president@ixda.org
          </a>
        </Li>
        <Li>
          Andrea Mignolo, Vice President -{' '}
          <a
            href="mailto:vp@ixda.org"
            sx={{ variant: 'variants.link', fontWeight: 'regular' }}
          >
            vp@ixda.org
          </a>
        </Li>
      </Ul>
    </CodeOfConductLayout>
  );
};

export default CodeOfConductPage;
